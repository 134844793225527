.textButton {
  color: #635353 !important;
  background-color: #c0d3df !important;
  width: 68px;
  align-content: center;
  border-radius: 12px;
  white-space: normal;
  height: 45px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}

.textButton:hover {
  background-color: #1063aa !important;
  color: white !important;
}

.mainButton {
  background-color: #005fab !important;
  color: white !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
}

.mainButton.deleteOKBtn {
  background-color: #e31212 !important;
}

.mainButton.deleteOKBtn:hover {
  color: #e31212 !important;
  border-color: #e31212;
}

.mainButton:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.cancelButton {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.88);
  width: 75px;
  height: 32px;
  font-size: 13px;
  border-color: #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
  text-align: center;
  align-items: center;
}

.cancelButton:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.cancelButton2 {
  background-color: #f0f0f070 !important;
  color: rgba(0, 0, 0, 0.88);
  width: 75px;
  height: 32px;
  font-size: 13px;
  border-color: #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.cancelButton2:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.disabledButton {
  background-color: #f0f0f0 !important;
  color: #00000047 !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
  pointer-events: none;
}

.disabledButton:hover {
  background-color: #f0f0f0 !important;
  color: #00000047 !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
  border-color: #e0e2e6 !important;
  pointer-events: none;
}

.iq3Button {
  background-color: #f9fcff !important;
  color: black !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
  border-color: black;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-radius: 0;
}

.iq3Button:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.editAllButton {
  background-color: #005fab !important;
  /* background-color: #8d161c !important; */
  color: white !important;
  width: 79px;
  height: 25px;
  font-size: 12px;
  padding: 0 !important;
}

.editAllButton:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 79px;
  height: 25px;
  font-size: 12px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.disabledEditButton {
  background-color: #f0f0f0 !important;
  color: #00000047 !important;
  width: 79px;
  height: 25px;
  font-size: 12px;
  padding: 0 !important;
  pointer-events: none;
}

.processTotalButton {
  color: white !important;
  height: 25px;
  font-size: 13px;
  padding: 0 !important;
  width: 160px;
  background-color: #4096ff !important;
  /* background-color: #b8860b !important; */
  /* background-color: #4f88bb !important; */
  /* background-color: #4096ff !important; */
  border: 1px solid #4096ff;
  margin-right: 10px;
}

.processTotalButton:hover {
  color: #1063aa !important;
  height: 25px;
  font-size: 13px;
  padding: 0 !important;
  width: 160px;
  background-color: #ffff !important;
  border: 1px solid #1063aa;
}

.ant-divider-horizontal.ant-divider-with-text {
  font-size: 13px;
  font-weight: 'bold' !important;
  color: #4a4747;
}

.ant-input-affix-wrapper > input.ant-input {
  font-size: 13px;
  color: #4a4747;
  /* text-align: right; */
}

.ant-table-wrapper .ant-table {
  font-size: 12.6px;
  color: #4a4747;
}

/* .tbody,
td,
tfoot,
th,
thead,
tr {
  background: #f6fcff;
} */

.parentListTbl-row {
  background: white;
}

.tooltip-text {
  font-size: 10px;
}

.purchaseTooltip .ant-tooltip-content {
  width: 200px;
}

/* .ant-tooltip-inner {
    color: #000000 !important;
    background-color: white !important;
  } */

.ant-select {
  font-size: 13px;
  /* color: red; */
  color: rgba(0, 0, 0, 0.88);
}

.iq3Collapse .ant-collapse .ant-collapse-content {
  border: 'none';
  border-top: 0px;
}

.iq3Collapse .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 5px 22px;
}

.iq3Collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 0px;
}

.iq3Collapse .ant-collapse > .ant-collapse-item {
  border-radius: 0 0 0 0;
}

.iq3Collapse .ant-collapse {
  border-radius: 0;
  background-color: white;
}

.iq3Collapse .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 0 0;
}

.iq3Collapse .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 0;
}

.iq3Collapse .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-inline-start: 0;
}

.ant-menu {
  color: black;
  font-size: 12px;
}

.ant-menu-light .paramMenu {
  /* background: #1063aa; */
  background: red;
}

.ant-menu .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu .ant-menu-submenu {
  height: 25px;
  width: 40px;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-vertical::shadow {
  border: 'none';
  background: white;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-horizontal::shadow {
  border: 'none';
  background: white;
}

.table .param-detail-tbl-title {
  text-align: center;
  background-color: #a6a6a6;
}

.table .param-detail-tbl-val-title {
  background-color: #1063aa;
  width: 125px;
  vertical-align: middle;
  color: white;
  font-weight: bold;
}

.parent-detail-adjustPrice-title {
  width: 125px;
  vertical-align: middle;
  text-align: right;
}

.table.tblPriceHistories {
  width: 50%;
}

.table .detail-tbl-row {
  height: 35;
}

/* .table .param-detail-tbl {
  padding: 0;
} */

.input-non-editable {
  pointer-events: none;
  border: none;
  /* background-color: #f5f4f4 !important; */
}

.input-editable {
  pointer-events: auto;
  border-color: #a5a5a5;
}

.input-non-editable-koteicalculate {
  pointer-events: none;
  border: none;
  background-color: #f0f0f070;
  /* background-color: #f5f4f4 !important; */
}

.input-label {
  pointer-events: none;
}

/* .tbListShow .ant-table-body {
  height: 682px !important;
  max-height: unset !important;
} */

.ant-table-row > td,
thead.ant-table-thead {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 16px !important;
}

.iq3-list-tb .ant-table-row > td {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 15px !important;
}

.iq3-list-tb.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 7px 7px !important;
  text-align: center;
  background-color: #d9d9d92e;
  color: #4a4747 !important;
  border-right: 0.5px solid #a5a5a52b;
  border-radius: 1px !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 2px 5px !important;
  border-bottom-width: 2px;
  border-bottom-color: #e5e5e5;
  text-align: center;
  border-right-width: 1px;
  border-right-color: #e5e5e58f;
}

.groupEditTable .ant-table-body {
  height: 47.9vh !important;
  max-height: unset !important;
}

.parent-detail-EditTable .ant-table-body,
.groupEditTable .ant-table-body {
  height: 75.2vh !important;
  max-height: unset !important;
}

.iq3-list-div {
  height: 100%;
  width: 99.5%;
  flex-direction: row;
  overflow: hidden;
  border-radius: 0.5rem;
  overflow-y: auto;
  margin-top: 7px;
}

.iq3-detail-div {
  height: 86%;
  width: 99.5%;
  /* display: flex; */
  flex-direction: row;
  overflow: hidden;
  border-radius: 0.5rem;
  /* overflow-y: auto; */
  display: 'flex';
  flex-grow: '1';
  margin-top: 10px;
  margin-left: 5px;
}

.child-detailTable .ant-table-body {
  /* height: 20vh !important; */
  height: 70vh !important;
  max-height: unset !important;
}

/* .child-detailTable .ant-table {
  width: 180vh;
} */

.groupEdittbInput {
  height: 28px;
}

.table .parent-detail-tbl-val-title {
  background-color: #1063aa;
  vertical-align: middle;
  color: white;
  font-size: 13px;
  width: 17%;
}

.table .parent-detail-tbl-val-title-right {
  background-color: white;
  vertical-align: middle;
  text-align: center;
  color: black;
  font-size: 13px;
  /* width: 30%; */
}

.table .child-iq3-tbl-val-title {
  background-color: #fafafa;
  width: 125px;
  vertical-align: middle;
  color: #4a4747;
  font-weight: bold;
}

.btn-ok-cancel {
  width: 75px;
}

input[type='text'] {
  font-size: 13px;
}

.groupEdittbInput {
  height: 20px;
}

.radioCustomerGpEdit label.ant-radio-wrapper span {
  font-size: 13px;
}

.radioCustomerGpEdit .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  width: 12px;
  height: 12px;
}

.radioCustomerGpEdit .ant-radio-wrapper .ant-radio-inner {
  width: 12px;
  height: 12px;
}

.ant-checkbox + span {
  font-size: 13px;
}

.ant-input-group-wrapper .ant-input-search .searchTxt {
  font-size: 30px;
}

.ant-pagination-total-text,
.ant-pagination .ant-pagination-item {
  font-size: 13px;
  color: black;
  font-weight: 'bold';
}

/* .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 8px 0px;
} */

/* .ant-table-cell .ant-table-cell-row:hover {
  background-color: #1063aa;
}

.ant-table-tbody > tr:hover {
  background: #1063aa;
} */

.active-row {
  /* background: #e6f4ff; */
  /* background: #fcfade; */
  background: #fcfade;
}

.active-row-parent-list {
  /* background-color: #4b7fad; */
  background-color: white;
}

.ant-table-wrapper .ant-table-tbody > tr.active-row > td.ant-table-cell-row-hover {
  background-color: #fcfade !important;
}

.ant-table-wrapper .ant-table-tbody > tr.active-row-parent-list > td.ant-table-cell-row-hover {
  background-color: #4b7fad !important;
  color: white !important;
}

.ant-table-wrapper .ant-table-tbody > tr.active-row-parent-list {
  background-color: #4b7fad !important;
  color: white !important;
}

.ant-table-wrapper .ant-table-tbody > tr.active-row-parent-list > td.ant-table-cell > span#typographyTxt {
  color: white !important;
}

.data-row {
  background: white;
  color: black;
}

.ant-table-thead .ant-table-cell {
  color: #1063aa !important;
  font-weight: bold;
}

.ant-modal-close {
  margin-top: -13px;
  background-color: white;
}

.ant-select-selector {
  font-size: 13px !important;
  font-weight: '500';
}

/* 絞込み設定 */
.filter-common {
  margin-left: 5px;
  margin-right: 10px;
}

.filter-font-size {
  font-size: 13px;
}

.filter-input {
  width: 213px;
  height: 27px;
  font-size: 13px;
}

.filter-input-range {
  height: 27px;
  font-size: 13px;
  padding: 3px;
}

.column-space {
  margin-left: -5px;
}

.registerModalStyle table td {
  border-left: 1px solid #eeebeb;
  border-right: 1px solid #eeebeb;
}

.registerModalStyle table th {
  border-left: 1px solid #eeebeb;
  border-right: 1px solid #eeebeb;
  border-top: 1px solid #eeebeb;
}

.registerModalStyle table td:last-child {
  border-right: none;
}

.addNewCustomerModal .ant-modal-content .ant-modal-footer {
  display: none;
}

.addNewCustomerModal .ant-modal-content {
  padding: 20px 10px 10px;
}

.pmxCloudFileModal .ant-modal-content {
  padding: 20px 10px;
}

/* Resize content */
.split-sash-content {
  background-color: #979694;
  /* height: 768px !important; */
}

/* .split-sash-content-active {
  background-color: #ebe8e4 !important;
} */
.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

.tbParentListShow .ant-table-body {
  height: 682px !important;
  max-height: unset !important;
}

/* 新規追加_main */
.addData .ant-modal-content .ant-modal-footer {
  text-align: center;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fcfade;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fcfade;
}

.ant-modal-body {
  font-size: 13px !important;
}

.ant-modal-footer {
  font-size: 13px;
}

.material-font {
  font-size: 13px !important;
}

.ant-divider-horizontal.ant-divider-with-text {
  color: #1063aa;
  font-size: 13px;
  font-weight: '700';
}

.ant-radio-button-wrapper {
  padding-inline: 10px !important;
}

.ant-radio-button-wrapper:hover {
  color: black;
  background-color: #cfe9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  /* color: white;
  background-color: #1063aa; */
  color: black;
  background-color: #cfe9ff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background-color: #1063aa;
}

/* td.ant-table-cell.ant-table-selection-column {
  width: 200px !important;
} */

/* th.ant-table-cell.ant-table-selection-column,
td.ant-table-cell.ant-table-selection-column {
  padding: 1px 0px !important;
} */

.ant-table-wrapper table tr td.ant-table-selection-column {
  text-overflow: clip;
  padding: 0px 0px !important;
}

.iq3Collapse .ant-collapse > .ant-collapse-item {
  border-bottom: 0px;
}

.iq3-detail-col-title-1,
.iq3-detail-col-title-2 {
  width: 90px;
  text-align: center;
  font-size: 13px;
  vertical-align: middle;
  color: #4a4747;
  font-weight: bold;
}

.iq3-detail-col-title-3 {
  width: 180px;
  text-align: right;
  font-size: 13px;
  border-right: 1px dashed rgb(181, 178, 174);
  padding: 5px;
}

.iq3-detail-col-title-4 {
  width: 195px;
  text-align: right;
  font-size: 13px;
  padding: 5px;
  vertical-align: middle;
  color: #4a4747;
  font-weight: bold;
}

.iq3-detail-col-label-4 {
  width: 195px;
  text-align: right;
  font-size: 13px;
  padding: 5px;
}

.iq3-detail-col-input {
  width: 320px;
  text-align: left;
  font-size: 13px;
}

.iq3-detail-input {
  width: 320px;
  height: 32px;
  font-size: 13px;
}

.iq3-detail-select {
  width: 65%;
  height: 32px;
  font-size: 13px;
}

.iq3-detail-row {
  height: 35px;
  align-items: center;
}

.ant-tabs .ant-tabs-tab {
  font-size: 13px !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1063aa;
  font-weight: 'bold';
  text-align: center;
}

.ant-tabs .ant-tabs-tab-btn {
  font-weight: 'bold';
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 5px 20px;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0px 0px 5px 0px;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-color: rgb(181, 178, 174);
}

.ant-tabs-top > .ant-tabs-nav::before {
  /* For the line to the right and close to the tabs */
  border-color: rgb(181, 178, 174);
}

.ant-tabs > .ant-tabs-nav {
  /* So that there is no gap between the content and tabs */
  margin-bottom: 0;
}

.iq3-detail-tab .ant-tabs-content-holder {
  /* padding: 5px; */
  /* border: 1px solid rgb(181, 178, 174); */
  border-top: transparent;
  /* Remove the top border so that there is no overlap*/
  /* height: 90%; */
  overflow-y: auto;
}

.ant-tabs.ant-tabs-top.ant-tabs-card.iq3-detail-tab {
  font-size: 13px !important;
  display: flex;
  width: 99.4%;
}

.ant-tabs.ant-tabs-top.ant-tabs-card.iq3-detail-tab-bulkedit {
  font-size: 13px !important;
  display: flex;
  width: 70.4%;
}

.ant-tabs.ant-tabs-top.ant-tabs-card.estimate-detail-tab {
  font-size: 13px !important;
  display: flex;
  width: 99.4%;
}

.ant-tabs.ant-tabs-top.ant-tabs-card.pmx-cloud-file-tab {
  font-size: 13px !important;
  display: flex;
  width: 100%;
  height: 750px;
}

.ant-list-split .ant-list-item {
  border-block-end: 0px;
}

.table .parent-estimate-tbl-val {
  vertical-align: 'middle';
  /* height: 28; */
}

.estimate-detail-tab .ant-tabs-content-holder,
.pmx-cloud-file-tab .ant-tabs-content-holder {
  padding: 5px;
  border: 1px solid rgb(181, 178, 174);
  /* Border for the content part */
  border-top: transparent;
  /* Remove the top border so that there is no overlap*/
  /* height: 82.5vh; */
}

.estimate-tab2-tbl-thead {
  font-size: 13px !important;
  font-weight: 'bold';
  background-color: #005fab;
  color: white;
  border-radius: 0;
  padding: 6px 15.5px;
  /* float: left; */
  width: 160px;
}

.estimate-tab2-title-row {
  height: 3vh;
}

.estimate-tab2-title-col {
  text-align: center;
  background-color: #005fab;
  color: white;
  padding: 3px;
  font-size: 13px;
  border: 1px solid rgb(181, 178, 174);
}

.estimate-tab2-row {
  height: 3.2vh;
}

.estimate-tab2-col {
  text-align: right;
  padding: 3px;
  color: black;
  font-size: 13px;
  font-weight: 400;
  border: 1px solid rgb(181, 178, 174);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.estimate-tab2-col-total {
  text-align: right;
  padding: 3px;
  font-size: 13px;
  /* font-weight: bold; */
  border: 1px solid rgb(181, 178, 174);
  background-color: #f8f7f66b;
}

.estimate-tab2-col-total-main {
  background-color: #9b9b9b21;
}

.csvoutput {
  background-color: #e28b44 !important;
  color: white !important;
  width: 65px;
  height: 30px;
  font-size: 13px;
  padding: 0 !important;
  border: 1px solid rgb(181, 178, 174);
  float: right;
}

.csvoutput:hover {
  background-color: #ffffff !important;
  color: #e28b44 !important;
  width: 65px;
  height: 30px;
  font-size: 13px;
  border-color: #e28b44;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.updateButton {
  background-color: #005fab !important;
  color: white !important;
  width: 65px;
  height: 26px;
  font-size: 13px;
  padding: 0 !important;
}

.updateButton:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 65px;
  height: 26px;
  font-size: 13px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.order-table-style {
  height: 68px;

  background-color: #fcfade;

  border-right: solid;

  border-right-width: 1px;

  border-right-color: #ebe8e4;
}

.order-table-text {
  font-weight: bold;

  margin-top: 25px;

  font-size: 13px;
}

.overtake-table-style {
  height: 300px;

  background-color: #fcfade;

  border-right: solid;

  border-right-width: 1px;

  border-right-color: #ebe8e4;
}

.overtake-table-text {
  font-weight: bold;

  margin-top: 150px;

  font-size: 13px;
}

.loss-table-style {
  height: 31px;

  background-color: #fcfade;

  border-right: solid;

  border-right-width: 1px;

  border-right-color: #ebe8e4;
}

.loss-table-text {
  font-weight: bold;

  margin-top: 5px;

  font-size: 13px;
}

.timecharge .ant-table-row > td {
  font-size: 12.6px;
  padding: 0px 0px !important;
}

.timecharge .form-control {
  border: none !important;
  border-radius: 0% !important;
}

.timecharge .ant-table-row {
  height: 30px;
}

.timecharge .input-non-editable {
  padding: 6px 12px;
}

.punch-table-style {
  height: 236px;

  background-color: #fcfade;

  border-right: solid;

  border-right-width: 1px;

  border-right-color: #ebe8e4;
}

.punch-table-text {
  font-weight: bold;

  margin-top: 105px;

  font-size: 12.6px;
}

.timecharge tr.selected,
td.ant-table-cell-row-hover input.input-non-editable,
tr.selected input.input-non-editable {
  background-color: #fcfade;
}

.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td.ant-table-cell-row-hover {
  background-color: #fcfade !important;
}

.ant-table-wrapper table tr td.ant-table-selection-column {
  text-overflow: clip;
}

.reference_prices td.input-non-editable {
  display: none;
}

.setupCommonCol {
  background-color: #fcfade;
  border-right: solid;
  border-right-width: 1px;
  border-right-color: #ebe8e4;
  display: grid;
}

p.setupCommonTxt {
  margin: auto !important;
}

.iq3Collapse .ant-collapse-small > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 5px;
}

.setupRow {
  border-bottom: solid 0.1px #ebe8e4;
}

.setupSetting tr.selected,
.setupSetting
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td.ant-table-cell-row-hover,
.setupSetting td.ant-table-cell-row-hover input.input-non-editable,
.setupSetting tr.selected input.input-non-editable {
  background-color: #fcfade;
}

.setupSetting .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-weight: bold;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  color: #1163aa;
}

.device-text {
  font-weight: bold;
  margin-top: 78px;
  font-size: 13px;
}

.device-bg-style {
  height: 175px;
  color: white;
  background-color: #005fab;
}

.sider-backgrount-style {
  background-color: #005fab;
}

.koutei .form-control {
  /* border: 1px solid white !important; */
  border: none !important;
  border-radius: 0% !important;
  padding: 0%;
}

.kouteical .ant-collapse-small > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.nestedCol .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0% !important;
}

.nestedCol .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  color: #4a4747 !important;
}

/* .ant-collapse-small >.ant-collapse-item >.ant-collapse-content>.ant-collapse-content-box */
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.kouteical .ant-collapse-small > .ant-collapse-item > .ant-collapse-header {
  background-color: #1063aa;
  height: 30px;
  color: white;
  padding: 4px 12px !important;
}

.koutei .ant-collapse-small > .ant-collapse-item > .ant-collapse-header {
  background-color: white !important;
  height: 20px;
  color: black;
  padding: 0px 4px !important;
  /* padding: 2px 10px !important; */
}

.ant-menu-vertical .ant-menu-submenu-title {
  padding-inline-end: 43px;
}

.estimate-collapse-col {
  background-color: #1063aa;
  vertical-align: middle;
  color: white;
  font-size: 13px;
}

/* oyabuhin */
.oyabuhin {
  background-color: #1063aa;
  height: 36px;
  padding: 0px 4px !important;
}

.deli-col {
  color: white;
  padding: 4px 12px !important;
}

.formula-column {
  padding: 9px 12px !important;
}

.input-col {
  border-right: 1px solid #eeebeb;
}

.oyabuhin-detail-col {
  padding: 4px 12px !important;
  border-right: 1px solid #eeebeb;
}

.oyabuhin-row {
  border: 1px solid #eeebeb;
}

.oyabuhin-detail {
  background-color: white !important;
  color: black;
  padding: 0px 4px !important;
}

.oyabuhin-col {
  border-right: 0.1px dashed white;
  color: white;
  padding: 4px 12px !important;
}

.ant-form label {
  font-size: 13px;
}

.tbPurchaseListShow .ant-table-body {
  /* height: 61vh !important; */
  height: 30.3vh !important;
  max-height: unset !important;
  overflow-x: auto;
}

.tbPurchaseListShow-sm .ant-table-body {
  /* height: 61vh !important; */
  height: 29vh !important;
  max-height: unset !important;
  overflow-x: auto;
}

.kouteiinput th.min {
  width: 12%;
}

.kouteiinput th.shorimin {
  width: 38%;
}

.ant-card .ant-card-body {
  padding: 7px;
  /* background: rgb(176, 173, 173); */
  background-color: #002060;
  color: white;
  /* color: black; */
  border-radius: 0px;
}

.ant-card-bordered {
  border: 0px solid #d9d9d9;
  /* background-color: #fbfdff; */
}

.yousetsu-select {
  width: 100%;
  font-size: 13px;
}

.yousetsu-row,
.kumitate-row {
  text-align: center;
  color: black;
  padding: 0px 4px !important;
  height: 33.43px;
  vertical-align: middle;
}

.yousetsu-col {
  vertical-align: middle;
  font-size: 13px;
  text-align: center;
  width: 4%;
  border: 0.5px solid #dee2e6;
}

.yousetsu-col-last {
  width: 4%;
  border: 0.5px solid #dee2e6;
}

.yousetsu-col .ant-select-single .ant-select-selector .ant-select-selection-item {
  font-weight: bold;
}

.yousetsu-col .ant-select-single .ant-select-selector {
  border: none;
}

/* .sankaku {
  transform: rotate(168deg);
  border: 0;
  border-top: 1px dashed #333;
  margin: 0;
  padding: 0;
  transform-origin: 24% 180%;
  width: 219%;
} */
/* .sankaku {
  transform: rotate(168deg);
  border: 0;
  border-top: 1px dashed #333;
  margin: 0;
  padding: 0;
  transform-origin: 24% 180%;
  width: 219%;
} */

.table.yousetsuTbl > :not(caption) > * > *,
.table.kumitateTbl > :not(caption) > * > * {
  padding: 0 !important;
}

/* .table.yousetsuTbl,
.table.kumitateTbl {
  border-collapse: collapse;
  border: 0.5px solid #dee2e6;
}
.table.yousetsuTbl td,
.table.kumitateTbl td {
  border: 0.5px solid #dee2e6;
  padding: 10px;
}
.table.yousetsuTbl th,
.table.kumitateTbl th {
  border: 0.5px solid #dee2e6;
  padding: 10px;
} */
.yousetsu-row-btn {
  border: none !important;
}

.yousetsu-col-btn {
  vertical-align: middle;
  text-align: center;
  width: 1%;
  border: none !important;
  background-color: none !important;
}

.kumitate-col {
  vertical-align: middle;
  font-size: 13px;
  text-align: center;
  border: 0.5px solid #dee2e6;
}

.kumitate-col-child {
  text-align: left;
  margin-left: 10px !important;
}

.kumitate-th {
  background-color: #1063aa !important;
  color: white;
  border: 0.1px solid #dee2e6;
}

.common-label-font-style {
  font-size: 13px;
}

.add-remove-icon {
  font-size: 19px;
  color: #1063aa;
}

.parent-pdetail-tbl-row {
  border: 1px solid #a5a5a5;
}

.parent-pdetail-tbl-val-title,
.table .parent-pdetail-tbl-val-title {
  background-color: #1063aa;
  vertical-align: middle;
  color: white;
  font-size: 13px;
  /* width: 10%; */
  display: flex;
  align-items: center;
}

.parent-pdetail-tbl-subtitle {
  background-color: #c0c0c05c;
  vertical-align: middle;
  color: #1063aa;
  text-align: center;
  font-size: 13px;
  width: 100%;
  /* border-bottom: 1px solid #a5a5a5; */
  border-right: 1px solid #a5a5a5;
}

.parent-pdetail-tbl-td {
  width: 100%;
  font-size: 13px;
}

.parent-pdetail-tbl-td-align-center {
  display: flex;
  align-items: center;
}

.parent-pdetail-tbl-td-mul {
  width: 100%;
  font-size: 13px;
  border-right: 1px solid #a5a5a5;
  border-top: 1px solid #a5a5a5;
  border-bottom: 1px solid #a5a5a5;
}

/* .parent-pdetail-tbl-td-mul-last {
  width: 100%;
  font-size: 13px;
  border-right: 1px solid #a5a5a5;
  border-top: 1px solid #a5a5a5;
} */

.parent-pdetail-tbl-td-tsuikaPro {
  width: 100%;
  font-size: 13px;
  border-right: 1px solid #a5a5a5;
  display: flex;
}

.process-pattern-list.ant-list.ant-list-split.ant-list-grid .ant-row {
  margin: 0 !important;
}

.process-pattern-list.ant-list.ant-list-split.ant-list-grid .ant-col {
  padding: 0 !important;
}

.ant-list-grid .ant-col > .ant-list-item {
  margin: 0;
}

.kouteiPatternDiv .ant-select-selector {
  height: 27px !important;
}

.kouteiPatternDiv .ant-select-selector > span.ant-select-selection-item {
  line-height: 23.5px !important;
}

.pdetail-ant-select {
  font-size: 13px;
  color: red;
  width: 85%;
}

.pdetail-ant-select-tsuikaPro {
  font-size: 13px;
  color: red;
  width: 82.5%;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-transfer .ant-transfer-operation .ant-btn .anticon {
  vertical-align: middle;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-transfer .ant-transfer-list-header {
  display: none;
}

/* :where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td,
:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table.ant-table-small tfoot > tr > th,
:where(.css-dev-only-do-not-override-10ed4xt).ant-table-wrapper .ant-table.ant-table-small tfoot > tr > td {
  border: none;
} */

#transfer-tbl tbody td {
  border: none;
  text-align: center;
}

#transfer-tbl td .ant-tag {
  width: 100%;
  border-width: unset;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);
  font-size: 13px;
  height: auto;
}

#transfer-tbl .ant-table-row > td,
thead.ant-table-thead {
  max-width: 100%;
  text-overflow: unset;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-transfer .ant-transfer-list {
  border-radius: 0;
}

#warning svg {
  width: 80%;
  height: 80%;
  /* outline-color: #ffd800; */
}

.add-remove-icon {
  font-size: 19px;
  color: #1063aa;
}

.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 13px;
  height: 500px;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-btn-primary {
  box-shadow: none !important;
}

button.ant-btn-primary.ant-btn-sm {
  background-color: rgba(0, 0, 0, 0);
  color: #4a4747;
  border: none;
  width: 20px;
  height: 40px;
}

.ant-transfer-operation .ant-btn .anticon-right {
  display: none;
}

.ant-transfer-operation .ant-btn .anticon-left {
  display: none;
}

button.ant-btn-primary.ant-btn-sm:hover {
  background: none;
  color: #4a4747;
}

/* button.ant-btn-primary.ant-btn-sm:active {
  border: none !important;
  box-shadow: 0 0px 0 white !important;
  color: red;
  transform: 0;
} */

.ant-transfer-operation .ant-btn .anticon-caret-right:hover {
  color: #4a4747;
}

.ant-transfer-operation .ant-btn .anticon-caret-left:hover {
  color: #4a4747;
}

.ant-btn-primary:disabled {
  cursor: pointer;
}

.ant-btn-primary:disabled .ant-wave {
  display: none;
}

/* ns 0411*/
.childPartLstTbl .ant-table-body {
  height: 615px !important;
  max-height: unset !important;
}

.table.propertiesTbl > :not(caption) > * > * {
  padding: 0 !important;
  height: 46.9px;
}

.detail-tbl-val-title-label {
  padding: 10px;
}

.table {
  font-size: 13px;
  width: 100%;
  /* border: 1px solid #a5a5a5 !important; */
}

.table.propertiesTbl,
.table.iq5-detail-tbl {
  border: 1px solid #a5a5a5 !important;
}

.table.processPatternTbl {
  border: 1px solid #a5a5a5 !important;
}

.table .detail-tbl-val-title {
  background-color: #1063aa;
  vertical-align: middle;
  /* color: #4a4747; */
  color: white;
  font-size: 13px;
  width: 25%;
  /* font-weight: 600; */
  border: 0.1px solid rgb(165, 165, 165);
  text-align: center;
}

.table .detail-tbl-val {
  vertical-align: middle;
}

.ant-select-selector {
  border: 1px solid #a5a5a5 !important;
  height: 30px !important;
  padding: 0 3px !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: 29px;
}

.ant-carousel .slick-dots {
  padding-inline-start: 310px;
}

.detailImage .ant-image-img {
  width: 233% !important;
  height: 233% !important;
}

.materialDetail {
  color: black !important;
  height: 25px;
  font-size: 13px;
  padding: 0 !important;
  width: 80px;
  background-color: #ffff !important;
  border: 1px solid #b5b2ae;
}

.materialDetail:hover {
  color: #4096ff !important;
  height: 25px;
  font-size: 13px;
  padding: 0 !important;
  width: 80px;
  background-color: #ffff !important;
  border: 1px solid #b5b2ae;
}

.materialDetail:active {
  color: #1677ff !important;
  height: 28px;
  font-size: 13px;
  padding: 0 !important;
  width: 80px;
  background-color: #ffff !important;
  border: 1px solid #b5b2ae;
}

/*親部品-工程選択画面ー開始*/
.pdetail-ant-tag {
  font-size: 13px;
  color: rgb(0 0 0 / 88%);
  width: 100%;
  height: 27px;
  border: 1px solid #a5a5a5 !important;
  /* margin-left: 4px; */
  margin-left: 30px;
  margin-inline-end: 3px;
}

.pdetail-ant-tag label[disabled] {
  color: rgb(0 0 0 / 88%);
  vertical-align: middle;
}

/*親部品-工程選択画面ー終了*/
/* 並び替え画面ー開始*/

.sortButton {
  background-color: #005fab !important;

  color: white !important;

  width: 32px;

  height: 75px;

  font-size: 15px;

  padding: 0 !important;
}

.multiArrow {
  display: inline-flex;

  flex-direction: column;
}

#sort-tbl .ant-table-thead {
  display: none;
}

#sort-tbl {
  margin: 3px;
}

.left-display {
  height: 509px;
  margin-left: 27px;
  border: 1px solid rgb(181, 178, 174);
  border-radius: 10px;
  padding: 5px;
  width: 90%;
}

.left-display [class*=' ant-space'] [class^='ant-space'] {
  min-width: 13.5%;
}

.right-display {
  margin-left: 120px;
}

#sort-tbl .ant-table-body {
  min-height: 502px;
}

#sort-tbl .ant-table-expanded-row-fixed {
  height: 501px;
}

#sort-tbl .ant-empty {
  margin-top: 100%;
}

/* 並び替え画面ー終了 */

/* 絞り込み画面ー開始 */
.shiborikomiTbl .table {
  width: 100%;
  border: 0px !important;
  vertical-align: top;
  padding: 0px;
}

.shiborikomiTbl .table > :not(caption) > * > * {
  padding: 0px !important;
}

.filterDataRows {
  gap: 0px !important;
}

/* 絞り込み画面ー終了 */
.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 0px;
}

.ant-input-affix-wrapper .ant-input-suffix {
  margin-inline-start: 0px;
  /* margin-top: -3px; */
  margin-top: 0px;
  font-size: 13px;
}

/* 工程積算*/

.ddl_2 {
  margin-left: 20px;
}

.ddl_3 {
  margin-left: 45px;
}

.ddl_2_noImg {
  margin-left: 25px;
}

.overflow-auto.kouteical.KouteiCalculate input,
.overflow-auto.kouteical.KouteiCalculate label {
  font-size: 13px;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 0px;
}
.ant-input-affix-wrapper.adjust-rate > input.ant-input,
.ant-input-affix-wrapper.adjust-rate > .ant-input-suffix {
  color: red !important;
}

.percent-input {
  border: 1px solid #d9d9d9;
}

.input:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px #719ece;
}

.kouteiCalculateDiv input,
.kouteiCalculateDiv .ant-select-selector {
  color: #000000;
  border: 1px solid #a5a5a5 !important;
  padding: 0 8px !important;
}

.kouteiCalculateDiv .oyabuhin-detail-col,
.kouteiCalculateDiv .input-col {
  display: flex;
  align-items: center;
}

.kouteiCalculateDiv .oyabuhin-detail-col .input-non-editable,
.kouteiCalculateDiv .input-col .input-non-editable,
.kouteiCalculateDiv .input-col .input-non-editable .ant-input-number-input {
  border: 0px solid red !important;
}

.input-editable.currency {
  border: 1px solid #a5a5a5 !important;
  width: 45% !important;
  margin: 0 5px;
  height: 25px;
  border-radius: 4px;
}

.input-editable.chargeAmount_currency {
  border: 1px solid #a5a5a5 !important;
  width: 98.2% !important;
  /* margin: 0 5px; */
  /* height: 25px; */
  border-radius: 4px;
}

input.input-editable.chargeAmount_currency:focus {
  outline: none;
}

.kouteiCalculateDiv .ant-input-number-input {
  width: 65%;
  border: 1px solid #a5a5a5 !important;
  height: 25px;
  border-radius: 4px;
}

/* .kouteiCalculateDiv .input-editable input{
  border: 1px solid #a5a5a5 !important;
} */

.kouteiCalculateDiv .input-editable,
.kouteiCalculateDiv .ant-select-selector {
  width: 100%;
  height: 25px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.kouteiCalculateDiv .input-editable,
.kouteiCalculateDiv .input-non-editable {
  margin: 0 5px;
}

.kouteiCalculateDiv .ant-select {
  width: 45% !important;
}

.kouteiCalculateDiv .ant-row.oyabuhin-detail.oyabuhin-row {
  height: 40px;
  padding: 0 5px !important;
}

.kouteiCalculateDiv .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 25px !important;
  font-size: 13px !important;
}

.ant-col.timer-input-col {
  width: 45%;
}

.formula-column span {
  border-bottom: 1px solid blue;
  line-height: 15px;
  color: blue;
}

.formula-column span:hover {
  border-bottom: 1px solid purple;
  line-height: 15px;
  color: purple;
}

.currencyIcon {
  display: flex !important;
  flex-direction: column;
  position: absolute;
  top: 4px;
  right: 7px;
}

.ant-input-number.input-editable.ant-input-number-focused {
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0);
}

.kouteiCalculateDiv span.react-numeric-input {
  width: 45%;
}

span.react-numeric-input input#gaichuAreaIn {
  border-radius: 4px;
}

b {
  margin-right: -6px;
}

.kouteiCalculateDiv .input-non-editable ~ b {
  display: none;
}

.kouteiCalculateDiv .ant-col {
  font-size: 13px !important;
}

.yosetsuDiv span.react-numeric-input {
  width: 50%;
}

b {
  margin-right: -6px;
}

.yosetsuDiv .input-non-editable ~ b {
  display: none;
}

.registerModal table td {
  border: 1px solid #a5a5a5;
}

.registerModal table th {
  border: 1px solid #a5a5a5;
}

.ant-col.timer-button-col {
  margin-left: 8px;
  margin-top: 3px;
}

/*パラメータ-共通-購入品と案件状態画面ー開始*/
.ant-picker .ant-picker-input input {
  font-size: 13px;
}

/*パラメータ-共通-購入品と案件状態画面ー終了*/

/* .customerSupplier .ant-table-body {
  height: 652px !important;
  max-height: unset !important;
} */

/*パラメータ-共通-係数画面ー開始*/
.drop-down-tbl .ant-table-body {
  height: 198px !important;
  max-height: unset !important;
}

/*パラメータ-共通-係数画面ー終了*/
.tbParentList .ant-table-body {
  max-height: 735px !important;
}

/* 共通-右寄せー開始 */
.cm-a-right {
  text-align: right;
}

/* 共通-右寄せー終了 */

/* 共通-真中寄せー開始 */
.cm-a-center {
  text-align: center;
}

.cm-a-left {
  text-align: left;
}

/* 共通-真中寄せー終了 */

/*sorting icon */
/* .tbParentList.ant-table-wrapper .ant-table-column-sorter {
  display: none;
} */

.ant-table-wrapper .ant-table-column-sorter {
  display: none;
}

.ant-table-wrapper td.ant-table-column-sort {
  background: none;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort:hover {
  background: #fafafa;
  /* cursor: default; */
}

.filterClearButton {
  background-color: #f8f9fa !important;
  color: #4a4747 !important;
  /* width: 70%; */
  height: 29px;
  font-size: 12px;
  padding: 0 !important;
  border-radius: 15px;
  border-color: #a5a5a5 !important;
}

.filterClearButton:hover {
  background-color: #eceff0 !important;
  color: #4a4747 !important;
  /* width: 70%; */
  height: 29px;
  font-size: 12px;
  border-color: #4a4747 !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}

.child-detailTable .ant-table-cell.ant-table-cell-row-hover {
  background-color: #fcfade !important;
}

.parentList-detailTable .ant-table-cell.ant-table-cell-row-hover {
  background-color: #fcfade !important;
}

.currencyIcon.reset {
  display: none !important;
}

.updateModalStyle .ant-table-wrapper .ant-table-thead > tr > th {
  /* padding: 2px 16.5px !important; */
  border-bottom-width: 2px;
  border-bottom-color: #e5e5e5;
  text-align: center;
}

/* .ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background-color: #ffffff !important;
} */

/* .ant-table-cell {
  cursor: pointer;
} */

.paramMenu-active.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #1677ff !important;
}

.paramMenu.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #4a4747 !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltiptext {
  visibility: hidden;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 0px 0px 6px #b2b2b2;
  position: absolute;
  z-index: 1;
  top: 29px;
  left: -310%;
  width: 200px;
  padding: 8px;
  font-size: 11px;
}

.tooltiptext::before {
  background-color: #fff;
  box-shadow: -2px -2px 2px 0 rgba(178, 178, 178, 0.4);
  content: '\00a0';
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: -6px;
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: 2px;
}

.currencyIcon:hover .tooltiptext {
  visibility: visible;
}

.tbParentList,
.child-detailTable,
.param_tbListShow,
.pattern_tbListShow,
.param_tbListShow_steelMaterialClass .drop-down-tbl {
  -moz-user-select: none;
  /* firefox */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE*/
  user-select: none;
  /* Standard syntax */
}

.ant-menu-vertical .ant-menu-submenu-title:hover {
  background-color: white !important;
}

/* 共通パラメータ画面のラベル表示 */
#param_detail_tbl input[type='text'] {
  padding: 5px;
  margin-left: 5px;
  width: 98.2%;
}

#param_detail_tbl [class^='ant-radio'],
#param_detail_tbl [class*=' ant-radio'] {
  margin-left: 2px;
}

#param_detail_tbl [class^='ant-image'],
#param_detail_tbl [class*=' ant-image'],
.ant-picker {
  margin-left: 5px;
}

#param_detail_tbl [class^='ant-tag'],
#param_detail_tbl [class*=' ant-tag'] {
  padding: 5px;
  margin-left: 5px;
  width: 40%;
  height: 32.42px;
}

.param_detail-title-label {
  padding: 10px;
}

.param_tbListShow .ant-table-body,
.tbParentList .ant-table-body {
  height: 735px !important;
  max-height: 735px !important;
}

.param_tbListShow_steelMaterialClass .ant-table-body {
  height: 840px !important;
  max-height: 840px !important;
}

.param_tbListShow-steel-material-name .ant-table-body {
  height: 725px !important;
  max-height: 735px !important;
  overflow-x: scroll !important;
}

.pattern_tbListShow .ant-table-body {
  height: 762px !important;
  max-height: 762px !important;
}

.tbParentList_dialog .ant-table-body {
  height: 500px !important;
  max-height: 500px !important;
}

#param_detail_tbl .ant-select-single {
  margin-left: 5px;
  width: 30%;
}

.tblPriceLots,
.tblAutoPurchase,
.tblPriceHistories {
  margin-left: 10px;
}

.tblPriceLots .detail-tbl-row,
.tblAutoPurchase .detail-tbl-row,
.tblPriceHistories .detail-tbl-row,
.tblPurchaseCategory .detail-tbl-row {
  text-align: center;
}

.tblPriceLots .detail-tbl-row td,
.tblAutoPurchase .detail-tbl-row td,
.tblPriceHistories .detail-tbl-row td,
.tblPurchaseCategory .detail-tbl-row td {
  border: 1px solid #a5a5a5;
}

.purchaseCategoryButton {
  background-color: white !important;
  color: #005fab !important;
  width: 115px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
  margin-left: 5px;
}

/* 共通パラメータ画面のラベル表示 */
/* kouteiCalculate */
.resetColor.red .ant-select-selection-item {
  color: red;
}

.resetColor-green {
  color: green;
}

.shiborikomiTbl .ant-picker {
  padding: 4px 5px 4px !important;
}

.estimate-tab.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  margin-left: 5px;
}

.ant-tabs.ant-tabs-top.ant-tabs-card.estimate-tab {
  font-size: 13px !important;
  display: flex;
}

.estimate-tab.ant-tabs-content-holder,
.iq3-detail-tab.ant-tabs-content-holder,
.iq5-detail-tab.ant-tabs-content-holder {
  border: 'none !important';
}

.estimate-tab.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border: none;
  padding: 5px 0px 5px 0px;
}

.estimate-tab.ant-tabs-top > .ant-tabs-nav::before,
.iq3-detail-tab.ant-tabs-top > .ant-tabs-nav::before,
.iq5-detail-tab.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.estimate-tab.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
  padding: 0;
}

.estimate-tab.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: -0.6px;
}

.estimate-tab-btn-active {
  width: 100px;
  position: relative;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  font-size: 15px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-block-start-width: 1.02px;
  border-inline-start-width: 1;
  border-inline-end-width: 1px;
  cursor: pointer;
  background-color: #1063aa;
  color: white;
  font-weight: 500;
}

.estimate-tab-btn {
  width: 100px;
  position: relative;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 15px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-block-start-width: 1.02px;
  border-inline-start-width: 1;
  border-inline-end-width: 1px;
  cursor: pointer;
  font-weight: 500;
}

.estimate-tab-btn:hover {
  background-color: #1063aa1f !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.estimate-tab-btn-active:hover {
  background-color: #1063aa !important;
  color: white !important;
}

.first-btn {
  border-top-right-radius: 1px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 5px;
}

.middle-btn {
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}

.last-btn {
  border-top-right-radius: 5px;
  border-top-left-radius: 1px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 1px;
}

.ResizePanel-module_ResizeHandleVertical__I0jId {
  background: none !important;
  border: none !important;
  display: none !important;
}

.iq3-detail-tab .ant-tabs-tab.ant-tabs-tab-active,
.iq5-detail-tab .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom-color: #b5b2ae !important;
  background: #fcfade;
}

.iq3-detail-tab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4a4747;
  font-weight: 'bold';
  text-align: center;
}

.sticky-style {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  width: 100%;
}

.addKouteiMaster .ant-select .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

span.anticon.anticon-plus-circle.add-icon,
span.anticon.anticon-close-circle.add-icon {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 19px;
  color: #1063aa;
}

.ant-input-disabled.input-non-editable,
.ant-input-disabled.input-editable {
  background-color: #ffffff;
  border: none;
  color: #000000;
  cursor: auto;
}

table.kouteiProcess td,
table.kouteiProcess.table tr th,
table.detailInfo table tr td,
div#showData .detailInfo > :not(caption) > * > *,
table.updHistory td,
table.updHistory.table tr th {
  border: 1px solid #a5a5a5;
  font-size: 12.6px;
  vertical-align: middle;
  padding: 3px;
}

table.kouteiProcess input,
table.detailInfo.table input,
table.kouteiProcess .ant-picker,
table.detailInfo.table .ant-picker {
  width: 100%;
  height: 24px !important;
  padding: 2px 4px;
  vertical-align: middle;
  font-size: 12.6px;
  text-align: center !important;
}

.kouteiMasterList .ant-table-body {
  height: 716px !important;
  max-width: unset !important;
}

.kouteiMaster table.detailInfo .ant-picker {
  margin-left: 0 !important;
  width: 100%;
}

table.kouteiProcess,
table.detailInfo.table {
  margin-bottom: 0px;
}

table.kouteiProcess {
  width: 92%;
}

.upperTbl {
  position: sticky;
  top: 0px;
  z-index: 1;
  background: #ffffff;
  width: 100%;
  padding: 0 10px 8px;
}

.addKouteiMaster .ant-select-disabled .ant-select-selector * {
  cursor: auto;
}

.kouteiMaster .ant-select-selector {
  height: 24px !important;
}

.kouteiMaster .ant-select-selector .ant-select-selection-item {
  line-height: 21px;
}

.table .device-detail-tbl-val-title {
  background-color: #1063aa;
  vertical-align: middle;
  color: white;
  font-size: 13px;
  width: 30%;
}

.table .device-detail-tbl-tsuika {
  background-color: #1063aa;
  vertical-align: middle;
  color: white;
  /* color: gray; */
  font-size: 13px;
  width: 30%;
}

.ant-list .ant-list-empty-text {
  display: none;
}

.materialClass div#showData .table .param-detail-tbl-val-title {
  width: 160px !important;
}

div#addNewMaterialClass .table .param-detail-tbl-val-title {
  width: 156px;
}

.ant-select.plusMinusDDL .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  width: 50px;
  flex: 1;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #a5a5a5 !important;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.ant-select.plusMinusDDL .ant-select-selector .ant-select-selection-item {
  line-height: 22px;
  font-size: 17px;
  font-weight: 300;
}

.plusMinusDDL.minus .ant-select-selector .ant-select-selection-item {
  font-size: 24px;
}

.ant-select.plusMinusDDL .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  width: 50px;
  flex: 1;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #a5a5a5 !important;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.ant-select.plusMinusDDL .ant-select-selector .ant-select-selection-item {
  line-height: 22px;
  font-size: 17px;
  font-weight: 300;
}

.plusMinusDDL.minus .ant-select-selector .ant-select-selection-item {
  font-size: 24px;
}

.ant-select.plusMinusDDL-parent-estimate .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  height: 30px !important;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.materialName .containerStyle {
  display: flex;
  align-items: center;
  border: 1px solid #a5a5a5;
  border-radius: 5px;
}

.materialName span.anticon.anticon-sync.submitUnitPrice {
  margin: -3px 15px 0 6px;
  color: #1063aa;
  font-weight: bold;
}

.materialName .ant-space:nth-child(2) {
  gap: 0 !important;
}

div#addNewMaterialName .ant-picker {
  height: 28px !important;
  margin-left: 0 !important;
}

div#pmxCloudFileFilter .ant-picker {
  height: 32px !important;
  margin-left: 0 !important;
}

div#addNewMaterialName table td {
  vertical-align: middle;
}

input.currencyInput {
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 5px;
  height: 30px;
}

input.currencyInput:hover,
input.currencyInput:focus {
  border-color: #4096ff;
  border-inline-end-width: 1px;
}

input.currencyInput:focus {
  border: 1px solid #4096ff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  outline: none;
}

input.currencyInputCost {
  border: 1px solid #a5a5a5;
  width: 100%;
  border-radius: 5px;
  height: 34px;
  margin: 5px;
}

input.currencyInputCost:hover,
input.currencyInputCost:focus {
  border-color: #4096ff;
  border-inline-end-width: 1px;
}

input.currencyInputCost:focus {
  border: 1px solid #4096ff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  outline: none;
}

.inputNumber input[type='text'] {
  margin-left: 0 !important;
  vertical-align: middle;
}

.addNewMaterialClassModal .ant-modal-content .ant-modal-footer {
  margin-top: 8px !important;
}

.updHistoryDiv {
  display: flex;
  justify-content: center;
}

table.updHistory.table {
  width: 97%;
  border-collapse: separate;
  border-spacing: 0px;
}

.ant-carousel .pattern-weldFinish-carousel .slick-dots-bottom,
.ant-carousel .pattern-surface-carousel .slick-dots-bottom {
  bottom: 49px;
}

.ant-carousel .pattern-weldFinish-carousel .slick-dots,
.ant-carousel .pattern-surface-carousel .slick-dots,
.ant-carousel .pattern-custom-carousel .slick-dots {
  display: none !important;
}

.ant-carousel .pattern-weldFinish-carousel .slick-prev,
.ant-carousel .pattern-surface-carousel .slick-prev {
  color: unset;
  top: 0%;
  margin-left: -20px;
  margin-top: 9px;
}

.ant-carousel .pattern-weldFinish-carousel .slick-next,
.ant-carousel .pattern-surface-carousel .slick-next {
  color: unset;
  top: 0%;
  margin-right: -15px;
  margin-top: 9px;
}

.ant-carousel .pattern-custom-carousel .slick-prev {
  color: unset;
  top: 0%;
  margin-left: -20px;
  margin-top: 33px;
}

.ant-carousel .pattern-custom-carousel .slick-next {
  color: unset;
  top: 0%;
  margin-right: -14px;
  margin-top: 33px;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.ant-carousel .slick-next::before,
.ant-carousel .slick-prev::before {
  content: '';
  display: none !important;
}

.ant-carousel .slick-prev::after,
.ant-carousel .slick-next::after {
  content: '';
  display: none !important;
}

.ant-wave {
  display: none !important;
}

.carousel-circleOutlined-active {
  color: #fff;
  background: #1063aa;
  border-radius: 20px;
}

.carousel-circleOutlined-inactive {
  color: #fff;
  background: #a5a5a5;
  border-radius: 20px;
}

.search-select-input {
  width: 170px;
  height: 30px;
  font-size: 10px;
}

.search-select {
  width: 90px;
  height: 30px;
  font-size: 10px;
}

.tblCustomer .ant-table-body {
  height: 350px !important;
  max-height: 350px !important;
}

.tblCustomerList .ant-table-body {
  height: 730px !important;
  max-height: 730px !important;
}

.table-container {
  overflow-x: auto;
}

.table-container table {
  table-layout: fixed;
}

.table-container th:first-child {
  position: sticky;
  left: 0;
  background-color: #1063aa !important;
  /* opacity: 1; */
  z-index: 1;
  /* pointer-events: none; */
}

.qty-table-container {
  overflow-x: auto;
}

.qty-table-container table {
  table-layout: fixed;
}

.fixedCol {
  position: sticky;
  left: 0;
  background-color: #1063aa;
  z-index: 1;
}

.table-container .table > :not(caption) > * > * {
  padding: 3px 0px !important;
}

.chargeAmount .table > :not(caption) > * > * {
  padding: 3px !important;
}

.updHistoryDiv::-webkit-scrollbar {
  width: 5px;
}

.updHistoryDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 20px;
}

.updHistoryDiv::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 20px;
}

.updHistoryDiv::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.gpEditModal td.ant-table-cell.ant-table-selection-column {
  padding: 5px 0px !important;
}

/* .gpEditModal .ant-select-selector,
.addNewMaterialClassModal .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
} */

/* エラーModal、Formのcss */
.input-error {
  background-color: #f5c4cb36 !important;
  box-shadow: 0 0 0 100px #f5c4cb36 inset;
  border-color: red;
}

.modal-error .ant-modal-content {
  padding: 20px 24px 10px 24px;
}

.modal-complete .ant-modal-content {
  padding: 20px 24px 10px 24px;
}

input.input-error:-webkit-autofill {
  background-color: #f5c4cb36 !important;
  box-shadow: 0 0 0 100px #f5c4cb36 inset;
  border-color: red;
}

input:-webkit-autofill {
  transition: background-color 600000s 0s;
  box-shadow: 0 0 0 100px #ffffff inset;
}

/* エラーModal、Formのcss */

table.master-table tr:last-child input[type='text'] {
  pointer-events: none !important;
}

table.master-table tr:last-child td:last-child {
  display: none;
}

.upperDeviceTbl {
  position: sticky;
  top: 0px;
  z-index: 3;
  background: #ffffff;
  width: 100%;
  padding: 0 10px 2px;
}

.tbl-header {
  background-color: #1063aa !important;
  color: white !important;
}

.souchi {
  pointer-events: none;
  background-color: #dfe4e7 !important;
  opacity: 0.9;
  color: #000;
}

.table.tsuikakoutei > :not(caption) > * > *,
.kouteiMaster .detailInfo.table > :not(caption) > * > *,
.kouteiMaster table.kouteiProcess > :not(caption) > * > * {
  --bs-table-bg: transparent;
  --bs-table-color: initial;
}

span#leftCarouselCircle {
  font-size: 21px;
}

span#rightCarouselCircle {
  font-size: 22px;
}

#remarks,
#remark {
  height: 85px !important;
}

.kouteiMaster .upperTbl .kouteiProcess #remark {
  height: 24px !important;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

/* .ant-table-column-sorters .ant-tooltip {
  display: none;
} */

.fixedCol2 {
  position: sticky;
  left: 0;
  background-color: #1063aa;
  z-index: 2;
}

.subTbl {
  /* max-width: 588px !important;
  min-width: 400px;
  overflow-x: auto !important; */
  padding: 0px !important;
}

#isAllocation {
  margin-left: 10px;
}

.supplier-search-icon {
  margin-left: 2px;
  margin-right: 3px;
  vertical-align: top;
}

.supplier-input-label {
  width: 90% !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

#addNewAutoReserve .param_tbListShow.commonPurchase {
  height: 350px !important;
  max-height: 350px !important;
}

#addNewAutoReserve .param_tbListShow.commonPurchase .ant-table-body {
  height: 270px !important;
  max-height: 270px !important;
}

div#addNewAutoReserve .ant-tabs.ant-tabs-top.ant-tabs-card.estimate-detail-tab.css-dev-only-do-not-override-1wazalj {
  width: 100%;
}

.autoReserveTbl .ant-table-body {
  height: 200px !important;
  max-height: 180px !important;
}

.tbl-header .ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #fff;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #1063aa;
}

.table-no-border-left tr:first-child td {
  border-left: none;
  border-top: none;
}

/* iQ3板金-材料詳細-CSS */
.pattern-btn {
  background-color: white !important;
  color: #005fab !important;
  width: 115px;
  height: 20px;
  font-size: 12.5px;
  padding: 0 !important;
}

.table-no-border-left .removerightborder {
  /* border-left: none; */
  border-right: none;
}

.table-no-border-left .removeleftborder {
  border-left: none;
  /* border-right: none; */
}

.table-no-border-left .removeBottomborder {
  border-bottom: none;
}

.table-no-border-left .listborder tr:last-child td {
  /* border-left: none; */
  border-bottom: none;
}

.iq3material-detail-list-table .ant-table .ant-table-header {
  border-radius: 0;
}

.iq3material-detail-list-table .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: unset;
}

.iq3material-detail-list-table .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: unset;
}

.iq3material-detail tr {
  text-align: center;
  vertical-align: middle;
  height: 37.42px;
}

.iq3material-detail-list-table .ant-table-thead .ant-table-cell {
  color: white !important;
  background: #1063aa !important;
  font-weight: bold;
}

.fixedCol3 {
  position: sticky;
  left: 130px;
  z-index: 1;
}

.fixedCol4 {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.row-primary {
  background-color: #007bff;
}

.fit-content9 {
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iq3material-detail > :not(caption) > * > * {
  padding: 3px 0.4rem !important;
}

.iq3material-pattern-detail .ant-modal-content .ant-modal-footer {
  display: none;
}

.iq3material-pattern-detail .ant-modal-content {
  padding: 20px 10px 10px;
}

.iq3material-detail {
  border: 1px solid rgb(165, 165, 165);
}

.iq3material-detail th {
  color: #1063aa !important;
  background: #fafafa !important;
}

.iq3material-detail-list-table .ant-table-body {
  height: 75.2vh !important;
}

/* iQ3板金-材料詳細-CSS */
table.upperReserveDetail tr td {
  border: 1px solid #a5a5a5;
  vertical-align: middle;
  text-align: center;
}

.upperReserveDetail > :not(caption) > * > * {
  padding: 3px !important;
  text-align: center;
}

div#addNewAutoReserve .ant-tabs-tab {
  width: 68px;
  display: flex;
  justify-content: center;
}

div#addNewAutoReserve .ant-tabs-card.estimate-detail-tab {
  margin-top: -8px;
}

div#addNewAutoReserve .ant-tabs-tab span.anticon.anticon-star {
  margin: 0 !important;
}

.ant-picker .ant-picker-clear {
  display: none;
}

.customPopover {
  font-size: 13px;
  width: 380px;
}

.ant-popover-inner {
  padding: 0 10px !important;
}

.ant-popover-title {
  background-color: #1063aa !important;
  margin: -10.5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 10px;
  padding: 5px 14px;
  color: #fff !important;
  font-size: 13px;
}

.footer {
  margin: 10px -10.5px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 10px;
}

.footer .ant-row.oyabuhin.oyabuhin-row {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 10px;
  border: 0px solid;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.search-bar {
  width: 100%;
}

.search-bar .ant-space-item {
  width: 98%;
}

/* .iq3-list-tb .ant-table-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}

.iq3-list-tb .ant-table-body {
  overflow: unset !important;
} */

.detailsButton {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.88);
  width: 58px;
  height: 26.4px;
  font-size: 13px;
  border-color: #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
  margin-left: 5px;
  margin-bottom: 2;
}

.chargeDetails {
  font-weight: 800;
}

.zaishitsukubun_fixedCol {
  position: sticky;
  left: 196px;
  background-color: #ccced0;
  z-index: 1;
}

.zaishitsukubun_header_fixedCol {
  position: sticky;
  left: 196px;
  background-color: #1063aa;
  z-index: 1;
}

.subTbl .removeTopborder {
  border-top: none;
}

.zaishitsu .removeTopborder {
  border-top: none;
}

.kakoujikan table {
  table-layout: auto !important;
}

.kakoujikan table td {
  /* border: none; */
  background-color: #ccced0;
}

.kakoujikan {
  padding: 0px !important;
  margin-top: 0px;
}

input#rate {
  text-align: right;
}

.charge_title {
  background-color: #e5e5e5 !important;
  color: #1063aa !important;
}

.fixedOneRowCol {
  position: sticky;
  left: 0;
  background-color: #e5e5e5;
  z-index: 1;
}

[data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 20px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

[data-theme='dark'] .site-navigation-steps {
  border-bottom: 1px solid #303030;
  margin-bottom: 20px;
  box-shadow: none;
}

.ant-steps {
  width: 98.5%;
}

.ant-steps.ant-steps-navigation .ant-steps-item::after {
  top: calc(50% - 1px);
  inset-inline-start: 100%;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-top: 1px solid #fff;
  border-bottom: none;
  border-inline-start: none;
  border-inline-end: 1px solid rgba(0, 0, 0, 0.25);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  content: '';
  background: #c6cacd;
  z-index: 1;
}

.ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-finish::after {
  top: calc(50% - 1px);
  inset-inline-start: 100%;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-top: 1px solid #fff;
  border-bottom: none;
  border-inline-start: none;
  border-inline-end: 1px solid rgba(0, 0, 0, 0.25);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  content: '';
  background: #1063aa;
  z-index: 1;
}

.ant-steps.ant-steps-navigation .ant-steps-item:first-child::after {
  top: calc(50% - 1px);
  inset-inline-start: 100%;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-top: 1px solid #fff;
  border-bottom: none;
  border-inline-start: none;
  border-inline-end: 1px solid rgba(0, 0, 0, 0.25);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  content: '';
  background: #1063aa;
  z-index: 1;
}

.ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-process.ant-steps-item-active::after {
  top: calc(50% - 1px);
  inset-inline-start: 100%;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-top: 1px solid #fff;
  border-bottom: none;
  border-inline-start: none;
  border-inline-end: 1px solid rgba(0, 0, 0, 0.25);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  content: '';
  background: #1063aa;
  z-index: 1;
}

.ant-steps.ant-steps-small .ant-steps-item-icon {
  display: none;
}

.ant-steps-item.ant-steps-item-process.ant-steps-item-active {
  background-color: #1063aa !important;
}

.ant-steps-item.ant-steps-item-wait {
  background-color: #c6cacd;
}

.ant-steps.ant-steps-navigation {
  padding-top: 10px;
  height: 55px;
}

.ant-steps.ant-steps-navigation .ant-steps-item-container {
  padding: 0px;
}

.ant-steps-item-title {
  color: white !important;
  margin-top: 10px;
}

.ant-steps.ant-steps-navigation .ant-steps-item:last-child {
  display: none;
}

.ant-steps.ant-steps-navigation .ant-steps-item:first-child {
  background-color: #1063aa;
}

.ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-process.ant-steps-item-active::before {
  background-color: #1063aa;
}

/* .ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-finish::before */
/* .ant-steps.ant-steps-navigation .ant-steps-item::before {
  background-color: #c6cacd;
} */
.ant-steps-item.ant-steps-item-finish {
  background-color: #1063aa;
}

ul.pmxType {
  list-style-type: square;
}

.parent-cloud-file-tb .ant-table-header,
.parent-cloud-file-iq3-tb .ant-table-header {
  border: 1px solid rgb(181 178 174 / 51%);
  border-radius: 0px 0px 0 0 !important;
}

.parent-cloud-file-tb .ant-table-body,
.parent-cloud-file-iq3-tb .ant-table-body {
  height: 580px !important;
  max-height: unset !important;
  border: 1px solid rgb(181 178 174 / 51%);
}

/* .parent-cloud-file-iq3-tb .ant-table-body {
  height: 542px !important;
  max-height: unset !important;
  border: 1px solid rgb(181 178 174 / 51%);
} */

.ant-tree {
  background: none !important;
}

.ant-tree .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-180deg);
}

.ant-tree .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 17px;
}

.ant-tree .ant-tree-switcher {
  width: 15px;
}

/* userSetting */

.setting {
  display: flex;
  height: 95.8vh;
  font-size: 13px;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: white;
  width: 182px;
}

.setting .ant-layout-sider {
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
}

.setting .ant-menu-item-active {
  width: 230px !important;
}

.setting .ant-menu-item-active :hover {
  width: 200px !important;
}

.setting .ant-menu-item:hover:not(.ant-menu-item-selected) {
  width: 200px !important;
}

.referenceButton {
  background-color: #005fab !important;
  color: white !important;
  width: 50px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
}

/* .fixedHeader {
  position: sticky;
  top: 0;
  z-index: 2;
} */
.settingContent label {
  font-size: 13px;
}

.canvasDiv {
  width: 527px;
  height: 290px;
  border: 1px solid #6c757dc7;
  padding: 10px 0px;
  overflow: hidden;
}

.canvasDivOnWheel {
  width: 100%;
  top: 0;
  left: 0;
  place-items: center;
  font: 700 12px system-ui;
}

.editInfoTle {
  pointer-events: none;
  text-align: center;
  width: 80px;
  padding: 3px;
  border: 2px dashed #005fab;
  border-radius: 7px;
  height: 27px;
}

.ant-input-affix-wrapper.editInfoTle > input.ant-input {
  font-size: 13px;
  color: #005fab;
  padding-left: 3px;
}

.ant-input-affix-wrapper.editInfoTle .ant-input-prefix {
  padding-left: 7px;
}

.red-border {
  border-color: red;
}

span.kouteiCal-proc-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  position: relative;
  cursor: pointer;
}

.koutebetsuDDLArrow {
  font-size: 18px;
  margin-left: 10px;
  margin-top: 5px;
}

.lbl-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  cursor: not-allowed;
}

.discardButton {
  background-color: #e31212 !important;
  color: white !important;
  width: 75px;
  height: 32px;
  font-size: 13px;
  vertical-align: middle;
  padding: 0 !important;
}

.ant-select-dropdown .ant-select-item-option-grouped {
  padding-inline-start: 14px;
}

.ant-select-item.ant-select-item-group .custom-label {
  font-size: 14px;
  color: #000;
}

.iq3-tab-div {
  overflow-y: auto;
  /* height: inherit; */
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.dataButton {
  background-color: #dc3545 !important;
  color: #fff !important;
  width: 53px;
  height: 26px;
  font-size: 10.5px;
  padding: 0 !important;
  font-weight: 900px;
}

.menu {
  font-size: 10.5px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
}

.menu-active-hover:hover {
  background-color: #a5a5a5 !important;
  border-color: #adb4bc !important;
  border-radius: 5px;
}

#param_detail_tbl .ant-select-multiple {
  margin-left: 5px;
}

.mulitple-select .ant-select-selector {
  min-height: 30px !important;
  height: auto !important;
}

.grossProfitCoeffBtn {
  color: white !important;
  height: 30px;
  font-size: 13px;
  padding: 0 !important;
  width: 100%;
  background-color: #4096ff !important;
  border: 1px solid #4096ff;
  margin-right: 10px;
}

.grossProfitCoeffBtn:hover {
  color: #1063aa !important;
  height: 30px;
  font-size: 13px;
  padding: 0 !important;
  width: 100%;
  background-color: #ffff !important;
  border: 1px solid #1063aa;
}

.bulkCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0ecb60d4;
  border-color: #0ecb60d4;
}

.ant-color-picker-trigger {
  margin-left: 5px;
  width: 20%;
}

.ant-color-picker-trigger .ant-color-picker-color-block {
  width: 100% !important;
}

.ant-color-picker .ant-popover-inner {
  padding: 10px !important;
}

:root {
  --list-estStatus-color: rgba(0, 0, 0, 0.88);
  --parent-estStatus-color: rgba(0, 0, 0, 0.88);
  /* hex value for dodgerblue color */
}

.list-estimate-status .ant-select-selection-item {
  color: var(--list-estStatus-color);
}

.parent-estimate-status .ant-select-selection-item {
  color: var(--parent-estStatus-color);
}

.csv-modal-complete .ant-modal-confirm-content {
  max-height: 250px;
  overflow-y: auto;
}

.displayCustomItem {
  pointer-events: none;
  opacity: 0.7;
  height: 509px;
  margin-left: 27px;
  border: 1px solid rgb(181, 178, 174);
  border-radius: 10px;
  padding: 5px;
  width: 90%;
  background-color: #c7c4c496;
}

.process-pattern-details-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.25);
  background: rgb(0 0 0 / 6%);
  cursor: not-allowed;
}

.formula-column-right-border {
  border-right: 1px solid #eeebeb;
}

.help-cmt-div {
  height: 40;
}

.help-cmt-p {
  font-size: 12;
  padding: 10;
}

.pmx-img {
  width: 100%;
  height: 47px;
  object-fit: scale-down;
}

.pmx-no-img {
  width: 0;
  height: 0;
}

.download-icon {
  font-size: 30px;
  color: #1063aa;
}

.download-delete-icon {
  font-size: 23px;
  color: red;
}
.oyabuhin-detail-col-d {
  padding: 4px 12px !important;
  border-right: 1px solid #eeebeb;
}

.oyabuhin-row-bg {
  background-color: #c7c4c496;
  color: #1063aa;
}

.oyabuhin-row-align {
  vertical-align: middle;
}

.param-currency {
  border: 1px solid #a5a5a5 !important;
  margin: 0 5px;
  border-radius: 6px;
}

.iq3-detail-btn {
  background-color: #f0f0f0 !important;
  width: 104px;
  height: 36px;
  padding: 0 !important;
  border-color: #a5a5a5b8;
  box-shadow: 3px 3px 3px #a5a5a5;
  border-radius: 15px;
}

.iq3-detail-btn:hover {
  background-color: #ffffff !important;
  color: #1063aa;
  width: 104px;
  height: 36px;
  padding: 0 !important;
  border: 1px solid #1063aa;
  box-shadow: 3px 3px 3px #a5a5a5;
  border-radius: 15px;
}

.iq3-detail-btn-active {
  background-color: #1063aa !important;
  color: white;
  width: 104px;
  height: 36px;
  padding: 0 !important;
  border-color: #a5a5a5b8;
  box-shadow: 3px 3px 3px #a5a5a5;
  border-radius: 15px;
}

.iq3-detail-btn-div {
  display: grid;
  align-items: center;
  text-align: center;
  margin-left: 15px;
}

.pmx-img-detail {
  width: 270px;
  height: 270px;
  object-fit: scale-down;
}

.search-select-input-steel-material {
  width: 65px;
  height: 30px;
  font-size: 10px;
}

.filter-img-btn {
  background-color: #1063aa !important;
  color: white !important;
  width: 95px;
  height: 27px;
  font-size: 13px;
  padding: 0 !important;
  border-color: #a5a5a5b8;
  box-shadow: 0 2px 0 #d9d9d9;
  border-radius: 27px;
}

.filter-img-btn:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 95px;
  height: 27px;
  font-size: 13px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-radius: 27px;
}

.filter-img-btn-active {
  background-color: #1063aa !important;
  color: #ffffff !important;
  width: 95px;
  height: 27px;
  font-size: 13px;
  padding: 0 !important;
  border-color: #a5a5a5b8;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border-radius: 27px;
}

.filter-img-btn-search {
  background-color: #d7dadd !important;
  color: white !important;
  width: 45px;
  height: 27px;
  font-size: 13px;
  padding: 0 !important;
  border-color: #a5a5a5b8;
  box-shadow: 0 2px 0 #d9d9d9;
  border-radius: 27px;
}
.param_tbListShow-steelMaterial .ant-table-body {
  height: 700px !important;
  max-height: 700px !important;
}

.param_tbListShow-steelMaterial-est .ant-table-body {
  height: 450px !important;
  max-height: 450px !important;
}
.img-search-txt {
  color: #ffffff !important;
  font-size: 12px;
  width: 87px;
  text-align: center;
  margin-bottom: 2px;
}
.img-search-div {
  width: 87px;
  height: 85px;
  border: 1px solid #a5a5a5 !important;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}
.steel-material-search-btn {
  width: 15px;
  background-color: #f0f0f0cf;
  height: 27px;
  border-radius: 4px;
  margin-left: 2px;
  margin-right: 2px;
}
.radio-steel-material-name-search {
  border: 1px solid rgba(165, 165, 165, 0.45);
  border-radius: 5px;
  margin-left: 5px;
  width: 238px;
  height: 30px;
  align-content: center;
}

.table-container .reset-spacing {
  margin: 0px;
  padding: 0px;
}

.section-margin-bottom {
  margin-bottom: 10px;
}

.iq5-material-select-btn {
  width: 45px;
  background-color: #4096ff !important;
  height: 25px;
  font-size: 12px;
  padding: 0 !important;
  color: white;
  border-radius: 10px;
}

.iq5-material-select-btn:hover {
  width: 45px;
  background-color: white !important;
  height: 25px;
  font-size: 12px;
  padding: 0 !important;
  color: #4096ff;
  border-radius: 10px;
}
.steel-shape-img {
  width: 100%;
  height: 100px;
  object-fit: scale-down;
}
.detail-iq5-tbl-val-title-label {
  padding-left: 7px;
}
.detail-iq5-tbl-val-title-label-width {
  width: 100px;
}
.steel-shape-img-div {
  border: 1px solid #a5a5a5;
  width: 120px;
  text-align: right;
  margin-left: 37px;
}
.table.iq5-detail-tbl > :not(caption) > * > * {
  padding: 0 !important;
  height: 30px;
}
.input-editable-iq5-detail {
  border-radius: 1px !important;
  padding: 3px !important;
}

.detail-tbl-val-bg {
  background-color: #d9d9d9bf !important;
}
.ant-select.materialInstruction .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  width: 100px;
  vertical-align: middle;
  border: 1px solid #a5a5a5 !important;
  border-radius: 0px;
  height: auto !important;
}
.iq5-dataType-btn {
  width: 85px;
  background-color: #e9f3ff !important;
  height: 28px;
  padding: 0 !important;
  color: black;
  border-radius: 4px;
  border: 1px solid #a5a5a5 !important;
}

.iq5-dataType-btn:hover {
  width: 85px;
  background-color: white !important;
  height: 28px;
  padding: 0 !important;
  color: #4096ff;
  border-radius: 4px;
}

.detail-tbl-val-title-iq5 {
  text-align: center;
}

.detail-iq5-tbl-val-title-label-amt {
  border-right: 1px solid #a5a5a5 !important;
}

.detail-tbl-val-process-iq5 {
  text-decoration: underline;
  color: #1063aa;
  cursor: pointer;
}

.iq5-detail-tbl-space {
  margin-left: 3px;
}

.table .detail-tbl-val-title-iq5-bg-color {
  background-color: #4a474754;
  vertical-align: middle;
  color: #000000cf;
  font-size: 13px;
  width: 25%;
  font-weight: 600;
  border: 0.1px solid rgb(165, 165, 165);
}
.detail-tbl-val-title-iq5-input {
  border: 1px solid #a5a5a5 !important;
  width: 97% !important;
  margin: 0 1px;
  height: 25px;
  border-radius: 4px;
}
.ant-tabs.ant-tabs-top.ant-tabs-card.iq5-detail-tab {
  font-size: 13px !important;
  display: flex;
  width: 99.4%;
}

.iq5-detail-sochi-btn {
  background-color: #005fab !important;
  color: white !important;
  width: 50px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
}

.iq5-detail-sochi-btn-del {
  background-color: #da3d3d !important;
  color: white !important;
  width: 50px;
  height: 32px;
  font-size: 13px;
  padding: 0 !important;
}

.iq5-detail-sochi-btn:hover,
.iq5-detail-sochi-btn-del:hover {
  background-color: #ffffff !important;
  color: #4096ff !important;
  width: 50px;
  height: 32px;
  font-size: 13px;
  border-color: #4096ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  padding: 0 !important;
}
.ant-select.deviceLst .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  width: 150px;
  vertical-align: middle;
  border: 1px solid #a5a5a5 !important;
  border-radius: 0px;
  height: auto !important;
}
.detail-iq5-tbl-val-title-label-right {
  text-align: right;
}
.detail-iq5-tbl-val-title-label-padding-right {
  padding-right: 5px;
}

.ant-select.processInputDetail .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  width: 160px;
  vertical-align: middle;
  border: 1px solid #a5a5a5 !important;
  border-radius: 0px;
  height: auto !important;
}
.table.iq5-detail-tbl {
  margin-bottom: 0rem !important;
}

.real-goku-setting-item-title {
  font-size: 20px;
  color: #212529bd;
  font-weight: bold;
}
.real-goku-setting-item-label-div {
  text-align: center;
  border: 1px solid #212529bd;
  width: 150px;
  background-color: #80808030;
  margin-bottom: 3px;
}
.real-goku-setting-item-label {
  font-size: 14px;
  color: black;
  font-weight: 400;
  padding: 0px;
}
.real-goku-setting-item-row {
  margin-top: 20px;
}
.real-goku-material-type-select-pattern {
  width: 200px;
  padding-left: 30px;
}
.real-goku-material-type-select {
  width: 100px;
  padding-left: 30px;
}
.real-goku-material-type-select .ant-select-selector,
.real-goku-material-type-select-pattern .ant-select-selector {
  border: none !important;
  display: flex;
  align-items: center;
  position: relative;
}
.real-goku-material-type-select .ant-select-arrow,
.real-goku-material-type-select-pattern .ant-select-arrow {
  position: absolute;
  left: 10px;
}
.real-goku-material-type-select .ant-select-selection-search,
.real-goku-material-type-select-pattern .ant-select-selection-search {
  margin-left: 30px;
}

.real-goku-material-type-select .ant-select .ant-select-arrow,
.real-goku-material-type-select-pattern .ant-select .ant-select-arrow {
  color: #000000;
}
.real-goku-plus-minus-icon {
  font-size: 18px;
  color: #212529bd;
  cursor: pointer;
}
.real-goku-setting-item-label-red {
  color: red;
  margin-left: 30px;
}
.real-goku-setting-item-chk {
  margin-left: 10px;
  margin-right: 10px;
}
.real-goku-setting-item-chk-date {
  margin-left: 35px;
}
.real-goku-setting-item-chk-date-width {
  width: 110px;
}
.real-goku-setting-item-chk-area-width {
  width: 90px;
}
.chk-color-red {
  color: red;
}
.real-goku-setting-div-padding {
  padding: 5px;
}
.real-goku-setting-div {
  padding-top: 10px;
  padding-left: 40px;
  padding-bottom: 10px;
  border: 2px solid #212529b0;
  overflow-y: scroll;
  height: 62.5vh;
}
.real-goku-setting-item-input {
  width: 70px;
  margin: 5px;
  height: 24px;
  text-align: right;
}
